export const FormFields = [
  {
    id: "first_name",
    required: true,
    label: "First Name",
    placeholder: "Type first name here",
    columns: "6"
  },
  {
    id: "last_name",
    required: true,
    label: "Last Name",
    placeholder: "Type last name here",
    columns: "6"
  },
  {
    id: "company",
    required: true,
    label: "Company",
    placeholder: "Type company name here",
    columns: "12"
  },
  {
    id: "job",
    required: true,
    label: "Job Title",
    placeholder: "",
    columns: "12"
  },
  {
    id: "email",
    required: true,
    label: "Work Email",
    placeholder: "Type work email here",
    columns: "12"
  }
];

export const messages = {
  walkthrough:
    "I would love to have a walkthrough in Loceye's platform. \n\
Could you send me an email with the available days/times to guide me through. \n\
Thank you in advance.",
  formatting:
    "I would love to use the [Format Proposed] in Loceye's platform \n\
but is not yet supported. Could you spare some time and support it in the next updates? \n\
Thank you in advance."
};
