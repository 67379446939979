import React from "react";
import { Link, graphql, navigate } from "gatsby";
import styled from "styled-components";

import Loader from "react-loader-spinner";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import { FormFields, messages } from "../components/ContactFieldConstants";
import SectionLayout from "../components/SectionLayout";
import Swal from "sweetalert2";

const Form = styled.div`
  label {
    font-size: 12px !important;
    line-height: 1;
    color: #999;
    margin-left: 1rem;
  }

  textarea {
    padding: 1rem;
  }
`;

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
`;

const Error = styled.div`
  color: red;
  font-weight: normal;
`;

const actions = ["formatting", "walkthrough"];

class Index extends React.Component {
  state = {
    first_name: "",
    last_name: "",
    company: "",
    message: "",
    job: "",
    email: "",
    bus_email: true,
  };

  componentDidMount() {
    const parsedUrl = new URL(window.location.href);
    const action = parsedUrl.searchParams.get("action");
    if (actions.includes(action)) {
      this.setState({ message: messages[action] });
    }
  }

  checkFields = (data) => {
    return (
      data.first_name != "" &&
      data.last_name != "" &&
      data.company != "" &&
      data.message != "" &&
      data.job != "" &&
      data.email != ""
    );
  };

  postForm = () => {
    const { first_name, last_name, company, message, job, email } = this.state;
    const url = "https://api.loceye.io/account/form";
    const data = {
      first_name,
      last_name,
      company,
      message,
      job,
      email,
    };
    // Check if email belongs to a company
    const reg = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,4})?$/;
    if (!reg.test(email)) {
      // Someone did not write the business email, hmmmmm
      this.setState({ bus_email: false, isLoading: false });
      return;
    }
    if (!this.checkFields(data)) {
      this.setState({ isLoading: false });
      Swal.fire({
        title: "Fill the form",
        text: "Please fill all the inputs and then send the form.",
        type: "error",
        position: "center",
        confirmButtonText: "Close",
      });
      return;
    }

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        Swal.fire({
          title: "Form Sent",
          text: "We will get in touch with you as soon as possible.",
          type: "success",
          position: "center",
          confirmButtonText: "Close",
          onClose: () => {
            navigate("/");
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ isLoading: false });
        Swal.fire({
          title: "Error Occured",
          text:
            "An error occured with the contact form. Feel free to send the form again or contact as through our email info@loceye.io",
          type: "error",
          position: "center",
          confirmButtonText: "Close",
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true }, this.postForm);
  };

  onFormChange = (message, id) => {
    const state = this.state;
    state[id] = message;
    this.setState(state);
  };

  onFormSubmit = () => {
    const { first_name, last_name, company, email, message, job } = this.state;
    const reg = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,4})?$/;
    if (!reg.test(email)) {
      // Someone did not write the business email, hmmmmm
      this.setState({ bus_email: false });
    } else if (true) {
      alert("I will submit");
      console.log(this.state);
    }
  };

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const { bus_email, isLoading } = this.state;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, `contact`]}
        />

        <SectionLayout
          description="To learn how Loceye can improve your design process and increase conversion arrange to speak to one of our experts."
          title="Contact Us"
        >
          <Form>
            <div className="container">
              <div class="row">
                <div class="col-md-10 col-lg-8">
                  <form
                    className="row contact_form"
                    onSubmit={(e) => {
                      this.onFormSubmit();
                      e.preventDefault();
                    }}
                  >
                    {FormFields.map((field, index) => {
                      return (
                        <div className={`col-md-${field.columns}`}>
                          <label>{field.label}</label>
                          <input
                            type="text"
                            style={{ backgroundColor: "#efefef" }}
                            name={field.id}
                            placeholder={field.placeholder}
                            required
                            id={field.id}
                            onChange={(e) =>
                              this.onFormChange(e.target.value, e.target.id)
                            }
                          />
                          {field.id == "email" && !bus_email ? (
                            <Error>This form accepts only work emails.</Error>
                          ) : null}
                        </div>
                      );
                    })}
                    <div className="col-md-12">
                      <label>Message:</label>
                      <textarea
                        id="message"
                        style={{ backgroundColor: "#efefef" }}
                        value={this.state.message}
                        onChange={(e) =>
                          this.onFormChange(e.target.value, e.target.id)
                        }
                        name="text"
                        placeholder="Message"
                        rows="4"
                      />
                    </div>
                    <div className="col-md-12">
                      <span>
                        By clicking sumbit you are agreeing to the{" "}
                        <Link to="/terms">Terms and Conditions</Link>
                      </span>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "2em" }}>
                      {isLoading ? (
                        <div className={`btn btn--primary my-shadow`}>
                          <Loader
                            type="ThreeDots"
                            color="#fff"
                            height="24"
                            width="24"
                          />
                        </div>
                      ) : (
                        <button
                          className={`btn btn--primary my-shadow`}
                          onClick={this.handleSubmit}
                        >
                          Send Form
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Form>
        </SectionLayout>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
